import React, { Component } from 'react'
import {
    Box,
    Button,
    Flex,
    Stack,
    Text
} from '@chakra-ui/react'
import axios from 'axios'
import DiscussionDetails from './DiscussionDetails'

class Discussions extends Component {
    constructor(props) {
        super(props)
        this.state = {
            discussions: null,
            discussionSelected: {}
        }
    }

    componentDidMount() {
        axios.get(`${process.env.GATSBY_API_URL}/discussion`)
            .then((response) => this.setState({ discussions: response.data }))
    }

    render() {
        const { discussions, discussionSelected } = this.state
        return (
            <Box
                fontFamily='Arial'
            >
                <Stack
                    spacing={2}
                    px={2}
                >
                    {discussions ?
                        discussions.map(discussion =>
                            <Box
                                key={`discussions-list-${discussion._id}`}
                                p={{ base: 1, lg: 4 }}
                                border='solid 2px'
                                borderColor={discussionSelected === discussion._id ? 'gray.200' : 'transparent'}
                            >
                                <Flex
                                    justify='space-between'
                                >
                                    <Text>
                                        {/* { discussion._id } */}
                                        {discussion.sender.name} → {discussion.receiver.name}
                                    </Text>
                                    <Box>
                                        <Button
                                            onClick={() => { this.setState({ discussionSelected: discussion }) }}
                                        >
                                            Details
                                        </Button>
                                    </Box>

                                </Flex>
                                {discussionSelected && discussionSelected._id === discussion._id ?
                                    <DiscussionDetails discussion={discussionSelected} />
                                    : null}
                            </Box>
                        )
                        : null}
                </Stack>
            </Box>
        )

    }
}


export default Discussions