import React, { Component } from 'react'
import {
    Badge,
    Box,
    Button,
    Flex,
    Stack,
    Text
} from '@chakra-ui/react'
import axios from 'axios'

class Payments extends Component {
    constructor(props) {
        super(props)
        this.state = {
            payments: null,
        }
    }

    componentDidMount() {
        axios.get(`${process.env.GATSBY_API_URL}/payment`)
            .then((response) => this.setState({ payments: response.data }))
    }

    render() {
        const { payments } = this.state
        return (
            <Box
                fontFamily='Arial'
            >
                <Stack
                    spacing={2}
                    px={2}
                >
                    {payments ?
                        payments.map(payment =>
                            <Box
                                key={`payments-list-${payments._id}`}
                                p={4}
                                borderBottom='solid 1px'
                                borderBottomColor='gray.100'
                            >
                                <Flex
                                    justify='space-between'
                                >
                                    <Box>
                                        <Text>
                                            {payment.user ? payment.user.name : ''} : {payment.amount / 100} €
                                        </Text>
                                        <Box>
                                            <Badge
                                                colorScheme={payment.status === 'succeeded' ? 'green' : 'orange'}
                                            >{payment.status}</Badge>
                                            <Badge>{payment.context}</Badge>
                                        </Box>
                                    </Box>
                                    <Box>
                                        <Button
                                        >
                                            Details
                                        </Button>
                                    </Box>

                                </Flex>

                            </Box>
                        )
                        : null}
                </Stack>
            </Box>
        )

    }
}


export default Payments