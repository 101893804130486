import React, { Component } from 'react'
import {
    Box,
    Button,
    Flex,
    FormLabel,
    Input,
    Select,
    Stack,
    Text
} from '@chakra-ui/react'

import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
} from "@chakra-ui/react"


import Axios from 'axios'
import Debug from '../utils/Debug'

class UserUpdateForm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            user: null
        }
    }

    componentDidMount() {
        this.getUserData()
    }
    getUserData() {
        Axios.get(`${process.env.GATSBY_API_URL}/user/${this.props.userId}`)
            .then((response) => this.setState({ user: response.data }))
    }

    handleChange(e) {
        let user = this.state.user;
        user[e.target.name] = e.target.value;
        this.setState({ user: user }, console.log('State', this.state));
    }

    handleSubmit(e) {
        e.preventDefault()
        Axios.patch(`${process.env.GATSBY_API_URL}/user/${this.props.userId}`, this.state.user)
            .then((response) => this.props.handleClose)
    }
    render() {
        const { user } = this.state
        return (
            <Box>

                <Modal
                    isOpen={true}
                    onClose={this.props.handleClose}
                >
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>Update user</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            <Debug data={user} />
                            {user ?
                                <Box as='form' onSubmit={(e) => this.handleSubmit(e)}>

                                    <FormLabel>Status ?</FormLabel>
                                    <Select
                                        name="status"
                                        value={user.status}
                                        onChange={(e) => this.handleChange(e)}
                                    >
                                        <option value={`admin`} selected={user.status === `admin` ? true : false}>Admin</option>
                                        <option value={`user`} selected={user.status === `user` ? true : false}>User</option>
                                        <option value={`spammer`} selected={user.status === `spammer` ? true : false}>Spammer</option>
                                    </Select>

                                    <FormLabel>Nom</FormLabel>
                                    <Input type="text" name="name" value={user.name} onChange={(e) => this.handleChange(e)} />
                                    <FormLabel>Email</FormLabel>
                                    <Input type="email" value={user.email} />
                                    <FormLabel>Phone</FormLabel>
                                    <Input type="text" value={user.phone} />
                                    <FormLabel>Currency</FormLabel>
                                    <Input type="text" value={user.currency} />
                                    <FormLabel>Locale</FormLabel>
                                    <Input type="text" value={user.locale} />
                                    <FormLabel>Pro ?</FormLabel>
                                    <Select>
                                        <option selected={user.isPro ? true : false}>Oui</option>
                                        <option selected={!user.isPro ? true : false}>Non</option>
                                    </Select>


                                    <Button type="submit">Update</Button>
                                </Box>
                                : null}

                        </ModalBody>


                    </ModalContent>
                </Modal>


                <Box>
                    ID : {this.props.userId}
                </Box>
                {/* <Stack isInline spacing={ 3 }>
                <Button as='a' href={`http://www.wanaboat.fr/identification/?token=${user.token}`}>Admin</Button>
                <Button onClick={ ()=>handleDelete()}>Delete</Button>
                <Button onClick={ ()=>startUpdate()}>Update</Button>
            </Stack> */}
            </Box>
        )
    }
}

export default UserUpdateForm