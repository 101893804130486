import React, { Component } from 'react'
import { EditorState, ContentState } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import '../components/Form/editor.css'



import { convertToRaw, convertFromRaw } from 'draft-js';
import { draftToMarkdown, markdownToDraft } from 'markdown-draft-js';

import { FormControl, FormLabel, Box, Button, FormHelperText } from "@chakra-ui/react";
import { FormattedMessage, injectIntl } from 'react-intl'


class AdEditor extends Component {
    constructor(props) {
        super(props);

        if (props.src) {
            console.log(props.src)
            // Update Ad Case
            // Convert input from markdown to draftjs state
            const markdownString = props.src
            const rawData = markdownToDraft(markdownString)
            const contentState = convertFromRaw(rawData)
            var newEditorState = EditorState.createWithContent(contentState)
        }

        else {
            // Create Ad Case
            var newEditorState = EditorState.createEmpty()
            var markdownString = ''
        }

        this.state = {
            // editorState: EditorState.createEmpty(),
            editorState: newEditorState,
            descMarkdow: markdownString
        }
    }

    onEditorStateChange = (editorState) => {
        this.setState({
            editorState,
        });
    };

    saveDescription = () => {

        this.setState({ isValid: true, isEditing: true })

        const { editorState } = this.state;
        const content = editorState.getCurrentContent();
        const dataToSaveBackend = convertToRaw(content);
        var markdownString = draftToMarkdown(dataToSaveBackend);
        this.setState({
            descMarkdow: markdownString
        });

        // console.log( markdownString )

        this.props.handleChange(markdownString);
        // this.props.closeEditor();

    }

    render() {
        const { editorState } = this.state;
        const { title, documents, uploadDir, typology } = this.props
        const toolbarOptions = {
            options: ['inline', 'blockType', 'list'],
            inline: {
                inDropdown: false,
                className: undefined,
                component: undefined,
                dropdownClassName: undefined,
                options: ['bold', 'italic', 'underline'],
                // bold: { icon: 'bold', className: undefined },
                // italic: { icon: 'italic', className: undefined },
                // underline: { icon: 'underline', className: undefined }
            },
            blockType: {
                inDropdown: true,
                options: ['Normal', 'H3', 'H4'],
                className: undefined,
                component: undefined,
                dropdownClassName: undefined,
            }
        };
        return (
            <FormControl>
                {/* <pre>
                    { JSON.stringify( this.props, null, 1 )}
                </pre> */}
                <FormLabel htmlFor="Title">
                    <FormattedMessage id="form.step6.describe.precisely.what.you.sell" />&nbsp;:
                </FormLabel>
                <Box
                    p={{ base: "1rem" }}
                    border="solid 1px"
                    borderColor="gray.200"
                    borderRadius="4px"
                    // minH="200px"
                    mb="1rem"
                >
                    <Editor
                        //   toolbarOnFocus="true"
                        // value="test"
                        placeholder={
                            typology === 'isBoat'
                                ?
                                this.props.intl.formatMessage({ id: 'form.step6.wysiwyg.boat.placeholder' })
                                // 'Décrivez le bateau que vous vendez dans les moindres détails'
                                :
                                this.props.intl.formatMessage({ id: 'form.step6.wysiwyg.other.placeholder' })
                            // 'Décrivez le matériel que vous vendez dans les moindres détails'
                        }
                        toolbar={toolbarOptions}
                        editorState={editorState}
                        wrapperClassName="demo-wrapper"
                        editorClassName="editor"
                        onEditorStateChange={this.onEditorStateChange}
                    />

                </Box>
                <FormHelperText id="email-helper-text">
                    {/* <FormattedMessage id="form.step5.notice" /> */}
                    {/* Voici notre proposition, si vous souhaitez la modifier, c'est possible. */}
                    {/* Vous pouvez mettre le lien vers une vidéo Youtube, elle sera automatiquement intégrée à l'annonce. */}
                </FormHelperText>

                <Button
                    mt={4}
                    variant="outline"
                    size='sm'
                    onClick={() => {
                        this.saveDescription()
                        this.setState({
                            'isValid': true,
                            'isEditing': false
                        })
                    }}
                >
                    <FormattedMessage id="form.step6.validate.this.description" />
                </Button>

            </FormControl>
        )
    }
}

export default injectIntl(AdEditor)