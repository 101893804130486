import React, { useState } from 'react'
import { Router, Link as ReachLink } from '@reach/router'
import {
    Box,
    Link,
    Flex,
    Stack,
    Spinner
} from '@chakra-ui/react'
import Layout from '../components/layouts/layoutFunnel'
import Helmet from 'react-helmet'

import Users from '../Admin/Users.js'
import Ads from '../Admin/Ads.js'
import Discussions from '../Admin/Discussions.js'
import Payments from '../Admin/Payments.js'
import AuthService from '../services/auth.service'


const Admin = () => {
    const [isAuthorized, setIsAuthorized] = useState(false)
    let isAdmin = AuthService.isAdmin();
    if (isAdmin) {
        isAdmin.then((result) => {
            if (result.status === 'admin') {
                setIsAuthorized(true)
            }
        })
    }
    // console.log( 'isAdmin', isAdmin.status )
    let Home = () => <div><a href="../">Back to website</a></div>

    return (
        <Layout>
            <Helmet>
                <title>Admin of the application</title>
            </Helmet>
            {!isAuthorized ? <Flex h='100vh' w='100vw' alignItems='center' justify='center'><Spinner /></Flex> :
                <>
                    <Flex
                        bg='white'
                        position='fixed'
                        top='0'
                        left='0'
                        h='40px'
                        borderBottom='solid 1px'
                        borderBottomColor='gray.100'
                        w='100%'
                        justify='center'
                        align='center'
                        zIndex='banner'
                    >
                        <Stack isInline spacing={5}>
                            <Link to='/admin' as={ReachLink}>Dashboard</Link>
                            <Link to='/admin/discussions' as={ReachLink}>Discussions</Link>
                            <Link to='/admin/users' as={ReachLink}>Users</Link>
                            <Link to='/admin/ads' as={ReachLink}>Ads</Link>
                            <Link to='/admin/payments' as={ReachLink}>Payments</Link>
                        </Stack>
                    </Flex>
                    <Box mt='40px' p={{ base: 2, lg: 10 }}>
                        <Router
                            basepath="/admin/"
                            default='/'
                        >
                            <Home path="/" />
                            <Users path="/users/" />
                            <Ads path="/ads" />
                            <Ads path="/ads/user/:userId" />
                            <Discussions path="/discussions" />
                            <Payments path="/payments" />
                        </Router>
                    </Box>
                </>
            }

        </Layout>
    )
}

export default Admin