import React, { Component } from 'react'
import { Link as RouterLink } from '@reach/router'
import {
    Box,
    Button,
    Flex,
    Stack,
    Text
} from '@chakra-ui/react'
import axios from 'axios'

const UserDetails = ({ user, handleDelete, startUpdate }) => {
    return (
        <Box>
            <Box>
                Email : {user.email}
            </Box>
            <Stack isInline spacing={3}>
                <Button as='a' href={`http://www.wanaboat.fr/identification/?token=${user.token}`}>Admin</Button>
                <Button onClick={() => handleDelete()}>Delete</Button>
                <Button onClick={() => startUpdate()}>Update</Button>
                <Button
                    as={RouterLink}
                    to={`../ads/user/${user._id}`}
                >
                    Ads
                </Button>
            </Stack>
        </Box>
    )
}

export default UserDetails