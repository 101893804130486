import React, { Component } from 'react'
import {
    Box,
    Button,
    Flex,
    Stack,
    Text
} from '@chakra-ui/react'
import axios from 'axios'
import { render } from 'react-dom'
import { MessageGallery } from '../components/Messages/gallery'
// import discussion from '../components/Messages/discussion'

class DiscussionDetails extends Component {
    constructor(props) {
        super(props)

        this.state = {
            messages: null
        }
    }
    componentDidMount() {
        axios.get(`${process.env.GATSBY_API_URL}/discussion/${this.props.discussion._id}`)
            .then((response) => this.setState({ 'messages': response.data.messages }))
    }
    render() {
        const { messages } = this.state
        const { discussion } = this.props
        return (
            <Box>
                <Box>
                    {/* <pre>
                    { JSON.stringify( this.props.discussion, null, 2 )}
                </pre> */}
                    {messages ?
                        <Stack spacing={3}>
                            {messages.map(message =>
                                <Flex
                                    color='white'
                                    key={message._id}
                                    justify={message.user === discussion.receiver._id ? 'flex-end' : ''}
                                >
                                    <Box
                                        bg={message.user === discussion.receiver._id ? 'blue.500' : 'blue.700'}

                                        borderRadius='sm'
                                        w='75%'
                                        p={3}
                                    >
                                        {message.content}

                                        {message.files ?
                                            <MessageGallery pictures={message.files} />
                                            : null
                                        }
                                    </Box>

                                </Flex>
                            )}
                        </Stack>
                        : null}
                </Box>
            </Box>
        )
    }
}

export default DiscussionDetails