import React, { useRef } from 'react'
import { Component } from 'react';

import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    CheckboxGroup,
} from "@chakra-ui/react";

import {
    Box,
    Button,
    Checkbox,
    Flex,
    Input,
    FormLabel,
    FormControl,
    Stack,
    SimpleGrid,
    Select,
    Text
} from '@chakra-ui/react'


import Axios from 'axios';

const AdContentEditor = typeof window !== `undefined` ? require("./AdContentEditor") : null


class AdUpdateForm extends Component {

    constructor(props) {
        super(props)
        this.state = {
            ad: null,
            modelImageName: null
        }
    }

    componentDidMount() {
        Axios.get(`${process.env.GATSBY_API_URL}/annonce/${this.props.adId}`)
            .then((response) => response.data ? this.setState({ ad: response.data }) : null)
    }

    // handleChange(key, value) {
    //     console.log(key, value)
    // }

    handleChange(key, value) {
        console.log(key, value)
        let ad = this.state.ad;
        ad[key] = value;
        this.setState({ ad: ad }, console.log('State', this.state));
    }

    handleSubmit(e) {
        e.preventDefault()
        Axios.patch(
            `${process.env.GATSBY_API_URL}/annonce/${this.props.adId}`,
            this.state.ad
        )
        console.log('submit')
    }

    promoteImageToModel(imgUrl, refModel) {
        console.log('promoteImageToModel', imgUrl, refModel, this.modelImageName.current.value)
        Axios.put(
            `${process.env.GATSBY_API_URL}/model/${refModel}/update-image/`,
            {
                img: imgUrl
            }
        )
    }

    promoteImageToModel = (imgUrl, refModel) => {
        if (!this.state.modelImageName) { alert('Image name mandatory') }
        else {
            Axios.put(
                `${process.env.GATSBY_API_URL}/model/${refModel}/update-image/`,
                {
                    img: imgUrl,
                    name: this.state.modelImageName
                }
            )
        }
    }

    render() {
        const { ad } = this.state



        return (
            <Modal
                isOpen={true}
                onClose={() => this.props.handleClose()}
                size='100vw'
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Update : {ad ? ad.titleFr : ''}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Box>
                            {ad ?
                                <Stack as='form' spacing={10} onSubmit={(e) => this.handleSubmit(e)}>
                                    <SimpleGrid columns={2} gap={4}>
                                        <FormControl>
                                            <FormLabel>Title FR</FormLabel>
                                            <Input type='text' name='titleFr' value={ad.titleFr} onChange={(e) => this.handleChange(e.target.name, e.target.value)} />
                                        </FormControl>
                                        <FormControl>
                                            <FormLabel>Title EN</FormLabel>
                                            <Input type='text' name='titleEn' value={ad.titleEn} onChange={(e) => this.handleChange(e.target.name, e.target.value)} />
                                        </FormControl>
                                    </SimpleGrid>
                                    <SimpleGrid columns={2} gap={4}>
                                        <FormControl>
                                            <FormLabel>Slug FR</FormLabel>
                                            <Input type='text' name='slugFr' value={ad.slugFr} onChange={(e) => this.handleChange(e.target.name, e.target.value)} />
                                        </FormControl>
                                        <FormControl>
                                            <FormLabel>Slug EN</FormLabel>
                                            <Input type='text' name='slugEn' value={ad.slugEn} onChange={(e) => this.handleChange(e.target.name, e.target.value)} />
                                        </FormControl>
                                    </SimpleGrid>
                                    <SimpleGrid columns={3} gap={4}>
                                        <FormControl>
                                            <FormLabel>Action</FormLabel>
                                            <Select name="action" onChange={(e) => this.handleChange(e.target.name, e.target.value)}>
                                                <option value='sell' selected={ad.action === 'sell' ? true : false}>Sell</option>
                                                <option value='search' selected={ad.action === 'search' ? true : false}>Search</option>
                                                <option value='rent' selected={ad.action === 'rent' ? true : false}>Rent</option>
                                            </Select>
                                        </FormControl>
                                        <FormControl>
                                            <FormLabel>Price</FormLabel>
                                            <Input type='number' name="price" value={ad.price} onChange={(e) => this.handleChange(e.target.name, e.target.value)} />
                                        </FormControl>
                                        <FormControl>
                                            <FormLabel>Currency</FormLabel>
                                            <Stack spacing={4} isInline>
                                                <FormControl display='flex'>
                                                    <Text>EUR</Text>
                                                    <Checkbox name='currency' value='EUR' isChecked={ad.currency === 'EUR'} onChange={(e) => this.handleChange(e.target.name, e.target.value)} />
                                                </FormControl>
                                                <FormControl display='flex'>
                                                    <Text>GBP</Text>
                                                    <Checkbox name='currency' value='GBP' isChecked={ad.currency === 'GBP'} onChange={(e) => this.handleChange(e.target.name, e.target.value)} />
                                                </FormControl>
                                                <FormControl display='flex'>
                                                    <Text>USD</Text>
                                                    <Checkbox name='currency' value='USD' isChecked={ad.currency === 'USD'} onChange={(e) => this.handleChange(e.target.name, e.target.value)} />
                                                </FormControl>
                                            </Stack>
                                        </FormControl>
                                    </SimpleGrid>
                                    {/* { AdContentEditor ? 
                                    <SimpleGrid columns={2} gap={4}>
                                        <Box>
                                            <AdContentEditor src={ad.descriptionFr} handleChange={(value) => this.handleChange('descriptionFr', value)} />
                                        </Box>
                                        <Box>
                                            <AdContentEditor src={ad.descriptionEn} handleChange={(value) => this.handleChange('descriptionEn', value)} />
                                        </Box>
                                    </SimpleGrid>
                                    : 'loading'} */}
                                    <Stack spacing={4} isInline>
                                        <FormControl>
                                            IsBoat
                                            <Checkbox name='isBoat' defaultIsChecked={ad.isBoat} />
                                        </FormControl>
                                        <FormControl>
                                            IsSail
                                            <Checkbox name='isSail' defaultIsChecked={ad.isSail} />
                                        </FormControl>
                                        <FormControl>
                                            IsPart
                                            <Checkbox name='isPart' defaultIsChecked={ad.isPart} />
                                        </FormControl>
                                        <FormControl>
                                            IsTrolley
                                            <Checkbox name='isTrolley' defaultIsChecked={ad.isTrolley} />
                                        </FormControl>
                                    </Stack>

                                    <FormControl>
                                        <FormLabel>Premium ?</FormLabel>
                                        <Select name="isPremium" onChange={(e) => this.handleChange(e.target.name, e.target.value)}>
                                            <option value='true' selected={ad.isPremium ? true : false}>Yes</option>
                                            <option value='false' selected={!ad.isPremium ? true : false}>No</option>
                                        </Select>
                                    </FormControl>

                                    <Box>
                                        <Button type='submit'>Save</Button>
                                    </Box>
                                    <Box overflow='scroll'>
                                        <Stack spacing={2}>
                                            <FormLabel>
                                                Images :
                                            </FormLabel>
                                            <Box>
                                                <Input
                                                    name='image'
                                                    placeholder='caravelle'
                                                    onChange={(e) => this.setState({ modelImageName: e.target.value })}
                                                />
                                            </Box>
                                        </Stack>
                                        <Stack isInline spacing={4}>
                                            {ad && ad.pictures && ad.pictures && ad.pictures.length && ad.pictures.map(item =>
                                                <Box w='200px'>
                                                    <img src={`${process.env.GATSBY_BASE_IMG_URL}${item}?twic=v1/cover=200x200/format=webp/quality=30`} />
                                                    <Flex my={2} justify='center'>
                                                        <Box>
                                                            <Button onClick={() => this.promoteImageToModel(item, ad.refModel)} size='sm'>Promote to ref model</Button>
                                                        </Box>
                                                    </Flex>
                                                </Box>
                                            )}
                                        </Stack>
                                    </Box>
                                </Stack>
                                : 'loading'}
                        </Box>
                    </ModalBody>
                </ModalContent>
            </Modal>
        )
    }
}

export default AdUpdateForm

