import React from "react"
import PropTypes from "prop-types"
import { ThemeProvider, CSSReset, Box } from '@chakra-ui/react'
import { IntlProvider } from 'react-intl';
import theme2 from "../../theme/";
import messagesFr from '../locales/fr'
import messagesEn from '../locales/en'

import '../../assets/fonts/stylesheet.css'

let lang = process.env.GATSBY_LANG
const messages = { ...messagesFr, ...messagesEn }

let i18nConfig = {
    locale: lang,
    messages: messages
};

const formats = {
    number: {
        EUR: {
            style: 'currency',
            currency: 'EUR'
        },
        GBP: {
            style: 'currency',
            currency: 'GBP'
        },
        USD: {
            style: 'currency',
            currency: 'USD'
        }
    }
};

const LayoutFunnel = ({ children }) => {
    return (
        <IntlProvider
            locale={lang}
            formats={formats}
            defaultFormats={formats}
            defaultLocale={i18nConfig.locale}
            messages={i18nConfig.messages[lang]}
        >
            <Box
                as="main"
                borderLeftStyle='solid'
                borderLeftColor='blue.brand'
                borderLeftWidth={{ base: '5px', lg: '10px' }}
                minHeight="100vh"
                // w={{ base:"calc(100vh - 5px", lg:"calc(100vh - 10px"}}
                pb={{ base: '100px', md: '0' }}
            >
                {children}
            </Box>
        </IntlProvider>
    )
}

LayoutFunnel.propTypes = {
    children: PropTypes.node.isRequired,
}

export default LayoutFunnel