import React, { Component } from 'react'
import { Link as RouterLink } from '@reach/router'
import {
    Box,
    Button,
    Grid,
    Flex,
    Input,
    Stack,
    Text,
    SimpleGrid,
    HStack,
    Avatar
} from '@chakra-ui/react'
import axios from 'axios'
import UserDetails from './UserDetails'
import UserUpdateForm from './UserUpdateForm'
import config from '../../config'

class Users extends Component {
    constructor(props) {
        super(props)
        this.state = {
            users: null,
            userSelected: "null",
            userToUpdate: null

        }
    }

    componentDidMount() {
        this.getAllUsers()
    }
    getAllUsers() {
        axios.get(`${process.env.GATSBY_API_URL}/user`)
            .then((response) => this.setState({ users: response.data }))
    }

    getByEmail(email) {
        if (email !== '') {
            axios.get(`${process.env.GATSBY_API_URL}/user/admin-email/${email}`)
                .then((response) => this.setState({ users: response.data }))
        } else {
            this.getAllUsers()
        }
    }

    handleDelete(userId) {
        console.log(`Delete ${userId}`)
        axios.delete(`${process.env.GATSBY_API_URL}/user/${userId}`)
            .then();
    }



    render() {
        const { users, userSelected, userToUpdate } = this.state
        return (
            <Box
                fontFamily='Arial'
            >

                {userToUpdate ?
                    <UserUpdateForm userId={userToUpdate} handleClose={() => this.setState({ userToUpdate: null })} />
                    : null}

                <Stack spacing={4} mb={4} as='form'>
                    <SimpleGrid columns={1} gap={10}>
                        <Input onChange={(e) => this.getByEmail(e.target.value)} type='email' placeholder='email@domain.com' />
                    </SimpleGrid>
                    <Box>
                        <Button type='submit'>Search</Button>
                    </Box>
                </Stack>
                {/* <pre>
                    { JSON.stringify( this.state, null, 1 )}
                </pre> */}
                <Stack
                    spacing={2}
                // px={ 2 }
                >
                    {users ?
                        users.map(user =>
                            <Box
                                key={`users-list-${user._id}`}
                                p={4}
                                border='solid 2px'
                                borderColor={userSelected === user._id ? 'gray.200' : 'transparent'}
                            >
                                <Flex
                                    justify='space-between'
                                >
                                    <HStack>
                                        {user.profilePict ?
                                            <Avatar size='md' src={`${config.apiUrl}/document/${user.profilePict}`} />
                                            : null}
                                        <Text>
                                            {user.name}
                                        </Text>

                                    </HStack>
                                    <Button
                                        onClick={() => { this.setState({ userSelected: user._id }) }}
                                    >
                                        Details
                                    </Button>
                                </Flex>
                                {userSelected === user._id ?
                                    <UserDetails user={user} handleDelete={() => this.handleDelete(user._id)} startUpdate={() => this.setState({ userToUpdate: user._id })} />
                                    : null}
                            </Box>
                        )
                        : null}
                </Stack>
            </Box>
        )

    }
}


export default Users