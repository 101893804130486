import React, { Component } from 'react'
import {
    Badge,
    Box,
    Input,
    Grid,
    Button,
    Flex,
    Stack,
    Text
} from '@chakra-ui/react'
import axios from 'axios'
// import UserDetails from './UserDetails'
import AdUpdateForm from './AdUpdateForm'

class Ads extends Component {
    constructor(props) {
        super(props)
        this.state = {
            ads: null,
            adSelected: null,
            adToUpdate: null
        }
    }

    componentDidMount() {
        if (!this.props.userId) {
            axios.get(`${process.env.GATSBY_API_URL}/annonce`)
                .then((response) => this.setState({ ads: response.data }))
        } else if (this.props.userId) {
            axios.get(`${process.env.GATSBY_API_URL}/annonce/user/${this.props.userId}`)
                .then((response) => this.setState({ ads: response.data }))
        }

    }

    getAllAds() {
        axios.get(`${process.env.GATSBY_API_URL}/annonce`)
            .then((response) => this.setState({ ads: response.data }))
    }

    getfilteredAds(search) {
        if (search !== '') {
            axios.get(`${process.env.GATSBY_API_URL}/annonce/filter/${search}`)
                .then((response) => this.setState({ ads: response.data }))
        } else {
            this.getAllAds()
        }
    }

    refreshPremiumAds() {
        axios.put(`${process.env.GATSBY_API_URL}/annonce/premium-go-ahead`)
            .then(alert('Premium ads went ahead.'));
    }

    render() {
        const { ads, adSelected, adToUpdate } = this.state
        const { refreshPremiumAds } = this
        return (
            <Box
                fontFamily='Arial'
            >
                <Box>
                    <Button onClick={() => refreshPremiumAds()}>Refresh premium ads</Button>
                </Box>
                <Box>
                    <Input onChange={(e) => this.getfilteredAds(e.target.value)} type='text' placeholder='Annonce xyz' />
                </Box>
                {adToUpdate ?
                    <AdUpdateForm adId={adToUpdate} handleClose={() => this.setState({ adToUpdate: null })} />
                    : null}

                <Stack
                    spacing={2}
                    px={2}
                >
                    {ads && ads.length ?
                        ads.map(ad =>
                            <Box
                                key={`ads-list-${ad._id}`}
                                p={4}
                                borderBottom='solid 1px'
                                borderColor={adSelected === ad._id ? 'gray.200' : 'gray.100'}
                            >
                                <Flex
                                    justify='space-between'
                                    align='center'
                                >

                                    <Grid templateColumns='50px 1fr' gap={4}>
                                        <Box w='50px' mr='1rem' bg='gray.300' borderRadius={2} overflow='hidden'>
                                            {ad.pictures && ad.pictures.length && ad.status === 'publish' ?
                                                <img src={`${process.env.GATSBY_BASE_IMG_URL}${ad.pictures[0]}?twic=v1/cover=50x50/format=webp/quality=30`} />
                                                : null}
                                        </Box>
                                        <Box>
                                            <Text>
                                                {ad.titleFr}
                                                {/* {ad.titleEn} */}
                                            </Text>
                                            <Stack spacing={2} isInline>
                                                <Badge
                                                    colorScheme={ad.status === 'publish' ? 'green' : 'orange'}
                                                >{ad.status}</Badge>
                                                <Badge
                                                    colorScheme={ad.action === 'sell' ? 'blue' : 'purple'}
                                                >{ad.action}</Badge>
                                            </Stack>
                                        </Box>


                                    </Grid>

                                    <Stack isInline spacing={2}>
                                        {ad.status === 'publish' ?
                                            <Button
                                                as='a'
                                                size='sm'
                                                target='_blank'
                                                href={`https://app.contentful.com/spaces/xg7lbk8sfmzs/entries/${ad.cmsDocumentId}`}
                                            >
                                                Contentful
                                            </Button>
                                            : null}
                                        <Button
                                            size='sm'
                                            onClick={() => { this.setState({ adToUpdate: ad._id }) }}
                                        >
                                            Update
                                        </Button>
                                    </Stack>

                                </Flex>
                            </Box>
                        )
                        : null}
                </Stack>
            </Box>
        )

    }
}


export default Ads